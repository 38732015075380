import { Link, useNavigate } from '@remix-run/react';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoGamePack,
  type DtoProduct,
  type DtoVerifyCheckoutSessionResponse,
  EnumsOneTimePurchaseUpsellPromoCode,
  EnumsOrgSubscriptionPlan,
} from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import ApplaudImg from '../../assets/img/applaud.png';
import config from '../../config';
import { getEnv } from '../../config/getEnv';
import { marketing } from '../../config/marketing';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { uetq } from '../../tracking/bing';
import { getStaticAssetPath } from '../../utils/assets';
import { assertExhaustive, booleanify, err2s } from '../../utils/common';
import { useConfettiAnimation } from '../ConfettiAnimation';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { GamePackUtils } from '../Game/GamePack/utils';
import { Loading } from '../Loading';
import { useMyOrganizer } from '../Organization/hooks/organization';
import { ProductUtils } from '../Product/utils';
import { useUser } from '../UserContext';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';
import { OTPUpSellModal } from './OTPUpSellModal';

function sendConversionEvent(
  transactionId: string,
  amount: number,
  email: string | null,
  enabled = marketing(getEnv()).registrationMarketingTrackingEnabled
) {
  if (!enabled) return;

  // Google Ads
  gtag('event', 'conversion', {
    send_to: 'AW-10811798990/LhIlCKSR-_8YEM7ru6Mo',
    currency: 'USD',
    value: amount,
    transaction_id: transactionId,
  });

  // Bing Ads
  uetq('set', 'pid', {
    em: email,
  });
  uetq('track', 'purchase', {
    revenue_value: () => {
      return amount;
    },
    currency: 'USD',
  });
}

export function CheckoutDone(props: {
  pack: DtoGamePack;
  product: Nullable<DtoProduct>;
  headcount: number;
  priceId: string | null;
  transaction: Transaction | null;
  swr: SWRResponse<DtoVerifyCheckoutSessionResponse | undefined>;
  fireConfetti: () => void;
}) {
  const { pack, headcount, product, fireConfetti } = props;
  const user = useUser();
  const analytics = useOneTimePurchaseAnalytics();
  const priceTable = GamePackUtils.ActivePrices(pack);
  const price = priceTable.find((p) => p.id === props.priceId);

  const subscriptionPrice = useMemo(() => {
    if (!product || !price) return undefined;
    const prices = ProductUtils.ActivePricesBySmallestBillingInterval(product);
    return ProductUtils.FindPrice(prices, price.maxPlayers);
  }, [product, price]);

  useEffectOnce(() => fireConfetti());

  useEffectOnce(() =>
    sendConversionEvent(
      props.transaction?.id ?? '',
      price?.amount ?? 0,
      user.email
    )
  );

  const { data: pass, error: verifyError, isValidating, mutate } = props.swr;

  const navigate = useNavigate();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const navigateToScheduler = () => {
    const params = new URLSearchParams();
    params.set('game-pack-id', pack.id);
    params.set('back-to', '/events');
    params.set('from-otp', 'true');
    navigate(`/events/create?${params.toString()}`);
  };

  const navigateToCustom = () => {
    const params = new URLSearchParams();
    params.set('template-id', pack.id);
    navigate(`/custom-games/create?${params.toString()}`);
  };

  const navigateToNextStep = () => {
    if (pack.isUGCTemplate) {
      navigateToCustom();
    } else {
      navigateToScheduler();
    }
  };

  const triggerUpsell = useLiveCallback(async () => {
    const organization = user.organizer?.organization;
    const subscription = organization?.subscription;

    if (
      !product ||
      !subscriptionPrice ||
      !organization ||
      !subscription ||
      !organization.canTrial ||
      subscription.plan !== EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree
    ) {
      return;
    }

    const trialDays = parseInt(getQueryParam('od') ?? '14');

    return await triggerModal({
      kind: 'custom',
      element: (p) => (
        <OTPUpSellModal
          onComplete={p.internalOnConfirm}
          onCancel={p.internalOnCancel}
          pack={pack}
          subscriptionProduct={product}
          subscriptionPrice={subscriptionPrice}
          trialPeriodDays={trialDays}
          promoCode={
            EnumsOneTimePurchaseUpsellPromoCode.OneTimePurchaseUpsellPromoCodePostCheckout
          }
          completeBtnLabel='Finish Scheduling My Event'
        />
      ),
    });
  });

  const handleContinue = useLiveCallback(async () => {
    analytics.trackEventPurchaseConfirmationButtonClicked();
    sendConversionEvent(
      props.transaction?.id ?? '',
      price?.amount ?? 0,
      user.email
    );

    if (headcount <= 10) {
      await triggerUpsell();
    }
    navigateToNextStep();
  });

  useEffectOnce(() => {
    if (!booleanify(getQueryParam('trigger-upsell'))) return;

    const run = async () => {
      const resp = await triggerUpsell();
      if (resp) {
        navigateToNextStep();
      }
    };

    run();
  });

  const error = verifyError
    ? verifyError
    : isValidating
    ? null
    : pass?.completed === false
    ? 'We cannot verify your purchase. Please try again.'
    : null;

  return (
    <>
      <div className='text-tertiary font-medium text-2xl text-center'>
        {props.transaction?.type === 'invoice' ? (
          <>Your invoice has been sent!</>
        ) : (
          <>
            Thank you for the purchase!
            <br />
            Let’s get your team invited.
          </>
        )}
      </div>
      {props.transaction?.type === 'invoice' ? (
        <img alt='applaud' src={ApplaudImg} className='w-25 h-25' />
      ) : (
        <img
          alt='calendar'
          src={getStaticAssetPath('images/calendar-3d-icon.png')}
          className='w-25 h-25'
        />
      )}
      <div className='text-center'>
        {props.transaction?.type === 'invoice' ? (
          <>
            We’ll notify you when it’s paid so you can finish setting up your
            event!
            <br />
            In the meantime, check out our full library of team building
            experiences!
          </>
        ) : (
          <>
            We’ll confirm your date & time, invite your team and create a
            calendar event with the relevant info.
          </>
        )}
      </div>
      <div className='flex flex-col items-center justify-center gap-0.5'>
        {pass ? (
          <>
            {props.transaction?.type === 'invoice' ? (
              <Link
                to='/home'
                className='w-96 h-12.5 btn-primary flex items-center justify-center'
              >
                Browse Library
              </Link>
            ) : (
              <button
                type='button'
                className='w-56 h-15 btn-primary'
                onClick={handleContinue}
              >
                {'Invite Team'}
              </button>
            )}
          </>
        ) : (
          <button
            type='button'
            className='w-56 h-15 btn-primary flex items-center justify-center gap-1'
            disabled={isValidating}
            onClick={() => mutate()}
          >
            {isValidating && <Loading text='' />}
            {isValidating ? 'Verifying' : 'Verify Purchase'}
          </button>
        )}
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
      </div>
    </>
  );
}

type Transaction = {
  type: 'session' | 'paymentIntent' | 'invoice';
  id: string;
};

export function CheckoutCompleted(props: {
  pack: DtoGamePack;
  product: Nullable<DtoProduct>;
  headcount: number;
  priceId: string | null;
  sessionId: string | null;
  clientSecret: string | null;
  invoiceId: string | null;
}) {
  const { pack, sessionId, clientSecret, invoiceId } = props;
  const organizer = useMyOrganizer();
  const { fire: fireConfetti, canvasConfetti } = useConfettiAnimation();

  const transaction = useMemo<Transaction | null>(() => {
    if (sessionId) return { type: 'session', id: sessionId };
    if (clientSecret) return { type: 'paymentIntent', id: clientSecret };
    if (invoiceId) return { type: 'invoice', id: invoiceId };
    return null;
  }, [sessionId, clientSecret, invoiceId]);

  const swr = useSWRImmutable(
    `/stripe/checkout-sessions/${transaction?.id}/verify`,
    async () => {
      if (!transaction) {
        return {
          completed: true,
          customerName: '',
        } as DtoVerifyCheckoutSessionResponse;
      }
      switch (transaction.type) {
        case 'session':
          const verifyResp = await apiService.stripe.verifyCheckoutSession(
            transaction.id
          );
          return verifyResp.data;
        case 'paymentIntent':
          const stripe = await loadStripe(config.stripe.publishableKey);
          const resp = await stripe?.retrievePaymentIntent(transaction.id);
          return {
            completed: resp?.paymentIntent?.status === 'succeeded',
            customerName: '',
          } as DtoVerifyCheckoutSessionResponse;
        case 'invoice':
          return {
            completed: true,
            customerName: '',
          } as DtoVerifyCheckoutSessionResponse;
        default:
          assertExhaustive(transaction.type);
          break;
      }
    }
  );

  if (!organizer) return <></>;

  return (
    <>
      <OTPCheckoutLayout pack={pack} progress={80} background={false}>
        <div className='w-2/3 bg-modal rounded-2.5xl px-16 py-10 gap-10 flex flex-col items-center'>
          <CheckoutDone
            {...props}
            transaction={transaction}
            swr={swr}
            fireConfetti={fireConfetti}
          />
        </div>
      </OTPCheckoutLayout>
      {canvasConfetti}
    </>
  );
}
